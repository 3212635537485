import { lazy, Suspense, useEffect } from 'react';
import { Debug, Toaster } from '@shared/react-ui-core';
import { useAuth } from './context/auth';
import { FullPageSpinner } from './components/loader';
import AppProviders from './context';
import './output.css';

const loadAuthenticatedApp = () => import('./authenticated-app.js');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./unauthenticated-app.js'));

const PageWrapper = props => {
  try {
    return <div {...props}>{props.children}</div>;
  } catch (error) {
    console.log('CAUGHT RED HANDED!', error);
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    } else {
      throw error;
    }
  }
};

const Authenticated = () => {
  const { token } = useAuth();
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <Suspense fallback={<FullPageSpinner />}>
      {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

function App() {
  return (
    <PageWrapper>
      <AppProviders>
        <Authenticated />
      </AppProviders>
      <Toaster />
      <Debug />
    </PageWrapper>
  );
}

export default App;
