const theme = {
  colors: {
    overlay: '#F2EFEB',
    primary: '#3722D3',
    green: '#00CDB9',
    greenDark: '#00B29C',
    blue: '#008CFF',
    blueDark: '#274A67',
    orange: '#F27927',
    tan: '#F2EFEB',
    tanDark: '#E4DED6',
    accentDark: '#E4DED6',
    warning: '#E6312D',
    grey0: '#F5F5F5',
    grey200: '#E4DED6',
    grey400: '#979797',
    grey600: '#B7B8B9',
    grey800: '#515151',
    grey1000: '#002334',
  },
  fonts: {
    primary: `'Reader Pro', 'Helvetica', 'Arial', sans-serif`,
  },
  breakpoints: {
    xs: '319px',
    smd: '374px',
    sm: '576px',
    md: '767px',
    lg: '991px',
    lg2: '1100px',
    xl: '1200px',
    xxl: '2100px',
  },
};

export default theme;
